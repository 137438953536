<template>
	<v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
		<v-card class="pa-2">
			<v-card-title class="text-h5 pa-4">
				<h5>Create New Contact</h5>
			</v-card-title>
			<v-card-text class="pa-4">
				<v-form lazy-validation ref="formRef">
					<v-text-field
						outlined
						v-model="customer.firstname"
						label="First name"
						:maxLength="25"
						counter="25"
						:rules="firstNameRules"></v-text-field>
					<v-text-field
						outlined
						v-model="customer.lastname"
						label="Last name"
						:maxLength="30"
						counter="30"
						validate-on="blur"
						:rules="lastNameRules"></v-text-field>
					<v-text-field
						outlined
						v-model="customer.companyName"
						:rules="companyNameRules"
						label="Company name (Optional)"
						:maxLength="50"
						counter="50" />
					<v-text-field outlined v-model="emailAddress" :rules="emailRules" label="Email address (Optional)" />
					<div @paste.prevent="pasteEvent">
						<vue-tel-input
							valid-color="#000000"
							v-bind="vueTelProps"
							v-model="phoneNumber"
							@input="phoneNumberChanged"
							@validate="validatePhoneNumber"
							:style="{
								height: '54px',
								marginBottom: '8px',
								border: phoneNumber && !validPhone ? '2px solid red !important' : '',
							}" />
					</div>
					<div v-if="phoneNumber && !validPhone" class="v-text-field__details" style="padding: 0 12px">
						<div class="v-messages theme--light error--text" role="alert">
							<div class="v-messages__wrapper">
								<div class="v-messages__message">Please enter a valid phone number.</div>
							</div>
						</div>
					</div>
				</v-form>
			</v-card-text>
			<v-card-actions class="pt-0 pb-4">
				<v-spacer></v-spacer>
				<v-btn class="white-bg" rounded elevation="0" @click.native="cancel">Back</v-btn>
				<v-btn
					rounded
					elevation="0"
					color="primary"
					@click.native="agree"
					:loading="creating"
					:disabled="createButtonDisabled()"
					>{{ creating ? "Creating" : "Create" }}</v-btn
				>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import api from "../api";
	import { addEmailToCustomer, addPhoneNumberToCustomer } from "../helpers/utilities";

	export default {
		props: {
			name: String,
		},
		data() {
			return {
				dialog: false,
				resolve: null,
				reject: null,
				formRef: false,
				message: null,
				customer: {
					firstname: "",
					lastname: "",
					companyName: "",
					EmailAddresses: [],
					PhoneNumbers: [],
				},
				emailAddress: "",
				phoneNumber: "",
				validPhone: false,
				vueTelProps: window.VueTelProps,
				creating: false,
				options: {
					color: "primary",
					width: 500,
					zIndex: 200,
				},
			};
		},
		async mounted() {},
		methods: {
			open() {
				this.dialog = true;
				this.resetForm(this.name);
				return new Promise((resolve, reject) => {
					this.resolve = resolve;
					this.reject = reject;
				});
			},
			resetForm(name) {
				this.$nextTick(() => {
					this.$refs.formRef.reset();
					this.customer.EmailAddresses = [];
					this.customer.PhoneNumbers = [];
					this.phoneNumber = "";
					this.emailAddress = "";
					this.customer.firstname = "";
					this.customer.lastname = "";
					this.customer.companyName = "";
					if (name) {
						const trimmedName = name.trim();
						if (trimmedName.includes(" ")) {
							this.customer = {
								...this.customer,
								firstname: trimmedName.split(" ")[0],
								lastname: trimmedName.split(" ").slice(1).join(" "),
							};
						} else {
							this.customer = { ...this.customer, firstname: trimmedName };
						}
					}
				});
			},
			async agree() {
				try {
					const isValidated = this.$refs.formRef.validate();
					if (isValidated) {
						this.creating = true;
						if (this.emailAddress) {
							this.customer = addEmailToCustomer(this.customer, this.emailAddress);
						}
						this.customer = addPhoneNumberToCustomer(this.customer, this.phoneNumber);
						const record = await api.createCustomer(this.customer);
						const customer = await api.getCustomer(record.id);
						this.resolve(customer);
						this.resetForm("");
						this.dialog = false;
						this.creating = false;
					}
				} catch (e) {
					if (e.message.includes("already exists")) {
						let errorMessage = e.message.replace("customer", "contact");
						if (errorMessage.includes("this email")) {
							errorMessage = errorMessage.replace("this email", `the email ${this.emailAddress}`);
						} else {
							errorMessage = errorMessage.replace(
								"this phone number",
								`the phone number ${this.phoneNumber}`
							);
						}
						this.$root.$confirm.open("Error", errorMessage, {}, false, "OK", null, true, true, true);
					} else {
						alert(e.message);
					}
				} finally {
					this.creating = false;
				}
			},
			cancel() {
				this.resetForm("");
				this.dialog = false;
				this.resolve(false);
				this.$emit("createCustomerDialogClosed");
			},
			pasteEvent(event) {
				this.phoneNumber = event.clipboardData?.getData("text")?.replace(/\D/g, "");
			},
			phoneNumberChanged(number, phoneObject) {
				if (this.validPhone) {
					this.$nextTick(() => {
						this.phoneNumber = phoneObject.formatted;
					});
				}
			},
			validatePhoneNumber(args) {
				this.validPhone = args.valid;
			},
			createButtonDisabled() {
				return this.creating || !this.$refs.formRef?.validate() || !this.validPhone;
			},
		},
		computed: {
			firstNameRules() {
				const rules = [];
				rules.push((v) => !v || v.length <= 25 || "Please enter a shorter first name.");
				return rules;
			},
			lastNameRules() {
				const rules = [];
				rules.push((v) => !v || v.length <= 30 || "Please enter a shorter last name.");
				return rules;
			},

			companyNameRules() {
				const rules = [];
				rules.push((v) => !v || v == "" || v.length <= 50 || "Please enter a shorter company name.");
				return rules;
			},
			emailRules() {
				const rules = [];
				rules.push((v) => !v || v == "" || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid.");
				rules.push((v) => !v || v == "" || v.length <= 125 || "Please enter a shorter email.");
				return rules;
			},
		},
	};
</script>

<style scoped>
	.text-h7 {
		color: rgba(0, 0, 0, 0.87);
	}
</style>
