<template>
	<v-card :class="cardClass()">
		<v-tabs v-model="currentTab" hide-slider grow icons-and-text touchless style="display: none">
			<v-tab active-class="tab-active-live-call" :class="tabClass" href="#live-call">
				Live Call
				<v-icon> icon-circle-arrow-right-solid </v-icon>
			</v-tab>
			<v-tab active-class="tab-active-schedule" :class="tabClass" href="#schedule">
				Schedule
				<v-icon>icon-calendar-solid</v-icon>
			</v-tab>
			<v-tab active-class="tab-active-record" :class="tabClass" href="#async-recording">
				Record
				<v-icon>icon-recording-circle</v-icon>
			</v-tab>
			<v-tab active-class="tab-active-request" :class="tabClass" href="#request-recording">
				Request
				<v-icon>icon-paper-plane-solid</v-icon>
			</v-tab>
			<v-tab active-class="tab-active-photo" :class="tabClass" href="#photo">
				Photo
				<v-icon>icon-paper-plane-solid</v-icon>
			</v-tab>
			<v-tab active-class="tab-active-upload-photo" :class="tabClass" href="#upload-photo">
				Upload Photo
				<v-icon>icon-paper-plane-solid</v-icon>
			</v-tab>
		</v-tabs>
		<v-tabs-items v-model="currentTab" touchless>
			<v-tab-item :value="'photo'">
				<ActionPhoto
					ref="actionPhoto"
					:action-text="actionText"
					:project-id="projectId"
					:actioning-text="actioningText"
					:allow-join="false"
					:button-icon="buttonIcon"
					:show-header="showHeader"
					@close-menu="close"></ActionPhoto>
			</v-tab-item>
			<v-tab-item :value="'upload-photo'">
				<ActionUploadPhoto
					ref="actionUploadPhoto"
					:action-text="actionText"
					:project-id="projectId"
					:actioning-text="actioningText"
					:allow-join="false"
					:button-icon="buttonIcon"
					:show-header="showHeader"
					@close-menu="close"></ActionUploadPhoto>
			</v-tab-item>
			<v-tab-item :value="'live-call'">
				<ActionVideo
					ref="actionVideoSync"
					:conversation-type="'sync'"
					:action-text="actionText"
					:actioning-text="actioningText"
					:allow-join="true"
					:project-id="projectId"
					:button-icon="buttonIcon"
					:show-header="showHeader"
					@close-menu="close"></ActionVideo>
			</v-tab-item>
			<v-tab-item :value="'schedule'">
				<ActionSchedule
					ref="actionSchedule"
					:project-id="projectId"
					:show-header="showHeader"
					@close-menu="close"></ActionSchedule>
			</v-tab-item>
			<v-tab-item :value="'async-recording'">
				<ActionStartRecording
					:project-id="projectId"
					ref="actionRecording"
					@close-menu="close"></ActionStartRecording>
			</v-tab-item>
			<v-tab-item :value="'request-recording'">
				<ActionVideo
					ref="actionVideoAsync"
					:conversation-type="'async'"
					:action-text="actionText"
					:actioning-text="actioningText"
					:project-id="projectId"
					:allow-join="false"
					:button-icon="buttonIcon"
					:show-header="showHeader"
					@close-menu="close"></ActionVideo>
			</v-tab-item>
		</v-tabs-items>
	</v-card>
</template>

<script>
	import "../helpers/emoji";
	import ActionVideo from "./ActionVideo.vue";
	import ActionPhoto from "./ActionPhoto.vue";
	import ActionUploadPhoto from "./ActionUploadPhoto.vue";
	import ActionSchedule from "./ActionSchedule.vue";
	import ActionStartRecording from "./ActionStartRecording.vue";
	import DeviceUtility from "@/helpers/device";

	export default {
		props: {
			conversationType: String, //sync or async
			customerId: String,
			actionText: String,
			actioningText: String,
			allowJoin: Boolean,
			buttonIcon: String,
			showHeader: Boolean,
			tabAction: String,
			projectId: String,
		},
		components: {
			ActionVideo,
			ActionSchedule,
			ActionPhoto,
			ActionStartRecording,
			ActionUploadPhoto,
		},
		data() {
			return {
				currentTab: null,
				vueTelProps: window.VueTelProps,
				starting: false,
				validPhone: false,
				autoSetName: false,
				autoSetNameValue: "",
				phone: "",
				name: "",
				includeEmailInvitation: "",
				meetingUrl: "",
				actioningTextOverride: "",
				organization: {},
				autoStartRecordings: true,
				notificationTemplate: "",
				defaultNotificationTemplate: "",
			};
		},
		computed: {
			tabClass() {
				return `tabs-${this.currentTab}`;
			},
			dynamicActioningText() {
				if (this.actioningTextOverride) {
					return this.actioningTextOverride;
				}
				return this.starting ? this.actioningText : this.actionText;
			},
			dynamicName() {
				return this.nameOverride ?? this.name;
			},
		},
		async mounted() {
			this.organization = this.$root.$organization;
			this.currentTab = this.tabAction;
		},
		methods: {
			cardClass() {
				return `conversation-card ${DeviceUtility.isMobile ? "conversation-bottom" : ""} ${
					this.conversationType
				}`;
			},
			close(isBack = false) {
				this.$emit("close-menu", isBack);
			},
			reset() {
				this.$refs.actionVideoSync?.reset();
				this.$refs.actionSchedule?.reset();
				this.$refs.actionRecording?.reset();
				this.$refs.actionVideoAsync?.reset();
				this.$refs.actionPhoto?.reset();
				this.$refs.actionUploadPhoto?.reset();
			},
		},
		watch: {
			currentTab: {
				handler(newValue) {
					this.reset();
				},
			},
		},
	};
</script>

<style lang="scss" scoped>
	.start-conversation-button {
		font-family: "Inter Tight", sans-serif;
		font-size: 20px;
		color: white;
		margin-left: 10px;
	}
	.reset-span {
		position: relative;
		left: calc(100% - 50px);
		display: inline-block;
		top: -30px;
		height: 0;
	}

	.conversation-card {
		border-radius: 12px;
		min-height: 400px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	@media screen and (max-width: 500px) {
		.conversation-card {
			min-height: 90vh;
			z-index: 101 !important;
		}
	}

	.conversation-card .header {
		display: flex;
		margin-bottom: 28px;
		margin-top: 12px;
	}

	.conversation-card textarea {
		color: #212121;
		font-size: 16px;
	}

	.conversation-card h2,
	.conversation-card i {
		font-size: 24px;
		margin-right: 5px;
	}

	.conversation-card .v-card__actions {
		padding: 16px;
		display: flex;
		justify-content: space-evenly;
	}

	.conversation-card .v-card__actions > button {
		width: 50%;
		height: 50px;
		border-radius: 25px;
		line-height: 20px;
		font-size: 1em;
		font-weight: 600;
	}

	.conversation-card .header {
		color: #000;
	}

	.conversation-card .auto-message {
		color: #b3b3b3;
		font-size: 16px;
	}

	.conversation-card .record-switch {
		margin-left: 5px;
		margin-top: 0px;
	}

	.conversation-card .v-card__actions > button:nth-child(2) {
		display: block;
		border: 1px solid #e0e0e0 !important;
	}

	.conversation-card .contact-name.reset {
		padding-top: 0px;
	}

	.field-border-radius {
		border-radius: 6px;
	}

	.v-slide-group__next,
	.v-slide-group__prev {
		display: none;
	}

	/* Action Highlights */

	.v-tab i {
		color: #000 !important;
	}

	.tab-active-live-call {
		background-color: #ffe6cc !important;
	}

	.tab-active-photo i {
		color: #ff8a00 !important;
	}

	.tab-active-photo {
		background-color: #ffe6cc !important;
	}

	.tab-active-upload-photo i {
		color: #ff8a00 !important;
	}

	.tab-active-upload-photo {
		background-color: #ffe6cc !important;
	}

	.tab-active-live-call i {
		color: #ff8a00 !important;
	}

	.tab-active-schedule {
		background-color: #bcf7ed !important;
	}

	.tab-active-schedule i {
		color: #009785 !important;
	}

	.tab-active-record {
		background-color: #ffcccc !important;
	}

	.tab-active-record i {
		color: #ff460b !important;
	}

	.tab-active-request {
		background-color: #d8ddff !important;
	}

	.tab-active-request i {
		color: #3d5afe !important;
	}

	.tabs-live-call {
		background-color: #fff8f1;
	}

	.tabs-photo {
		background-color: #fff8f1;
	}

	.tabs-upload-photo {
		background-color: #fff8f1;
	}

	.tabs-schedule {
		background-color: #f1fffc;
	}

	.tabs-async-recording {
		background-color: #fff0f2;
	}

	.tabs-request-recording {
		background-color: #f2f0ff;
	}
</style>
