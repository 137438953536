<template>
	<v-dialog
		v-model="dialog"
		:max-width="options.width"
		contained="true"
		:fullscreen="false"
		class="create-project-dialog"
		:z-index="options.zIndex"
		style="z-index: 2 !important"
		@keydown.esc="cancel"
		@keydown.enter.prevent>
		<v-card class="pa-2">
			<v-card-title class="text-h5 pa-4">
				<h5>Create a New Project</h5>
			</v-card-title>
			<v-card-text class="pa-4">
				<v-form ref="formRef" v-model="isFormValid">
					<v-text-field
						counter="255"
						:rules="nameRules"
						outlined
						v-model="project.name"
						label="Name (Required)"></v-text-field>
				</v-form>
			</v-card-text>
			<v-card-actions class="pt-0 pb-4">
				<v-spacer></v-spacer>
				<v-btn class="white-bg" rounded elevation="0" @click.native="cancel">Back</v-btn>
				<v-btn
					rounded
					elevation="0"
					color="primary"
					@click.native="agree"
					:loading="creating"
					:disabled="createButtonDisabled || !isFormValid"
					>{{ creating ? "Creating" : "Create" }}</v-btn
				>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import api from "../api";
	export default {
		data() {
			return {
				dialog: false,
				resolve: null,
				reject: null,
				formRef: false,
				message: null,
				project: {},
				creating: false,
				nameRules: [
					(v) => (v || "").trim().length > 0 || "Name is required.",
					(v) => (v || "").trim().length <= 255 || "Name must be less than 255 characters.",
				],
				options: {
					color: "primary",
					width: 460,
					zIndex: 2,
				},
				isFormValid: false,
			};
		},
		async mounted() {},
		computed: {
			createButtonDisabled() {
				return this.creating && this.project.name.length > 255;
			},
		},
		methods: {
			open(defaultName) {
				this.resetForm();
				this.project.name = defaultName ?? "";
				this.dialog = true;
				return new Promise((resolve, reject) => {
					this.resolve = resolve;
					this.reject = reject;
				});
			},
			resetForm() {
				this.$refs.formRef?.reset();
			},
			async agree() {
				try {
					const isValidated = this.$refs.formRef.validate();
					if (this.project.name.length > 255) {
						this.$root.$confirm.open(
							"Error",
							"Oops! Looks like the name you entered exceeds our 255 character limit. Please try again with a name that is less than 255 characters.",
							{},
							false,
							"OK",
							null,
							true,
							true,
							true
						);
					} else if (isValidated) {
						this.creating = true;
						const record = await api.createProject(this.project);
						this.resolve(record);
						this.resetForm();
						this.dialog = false;
						this.creating = false;
					}
				} catch (e) {
					if (e.message.includes("already exists")) {
						this.$root.$confirm.open("Error", e.message, {}, false, "OK", null, true, true, true);
					} else {
						alert(e.message);
					}
				} finally {
					this.creating = false;
				}
			},
			cancel() {
				this.resetForm();
				this.dialog = false;
				this.resolve(null);
			},
		},
	};
</script>

<style scoped>
	.text-h7 {
		color: rgba(0, 0, 0, 0.87);
	}

	.create-project-dialog {
		z-index: 3 !important;
	}
</style>
