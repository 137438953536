<template>
	<v-form>
		<v-container class="px-0">
			<template v-if="editing">
				<div class="d-flex flex-nowrap">
					<v-text-field
						outlined
						required
						label="Name of the Form *"
						class="field-border-radius"
						v-model.trim="form.name"
						:rules="[
							(value) => (!!value && value?.trim().length > 0) || 'Name of the Form is required.',
							(value) =>
								!value || value.length <= 150 || 'Name of the Form must not exceed 150 characters.',
						]"></v-text-field>
					<v-tooltip top v-if="$vuetify.breakpoint.smAndUp">
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon v-bind="attrs" v-on="on" class="help-button" :ripple="false">
								<v-icon size="24">icon-circle-help-stroke</v-icon>
							</v-btn>
						</template>
						<span>
							Give your form a title to help you identify it later in the LiveSwitch application. This
							title is just for you and won’t be visible to the people filling out the form.
						</span>
					</v-tooltip>
				</div>
				<div class="d-flex flex-nowrap">
					<v-text-field
						outlined
						required
						label="Notes on the Form"
						class="field-border-radius"
						v-model="form.notes"
						:rules="[
							(value) =>
								!value || value.length <= 250 || 'Notes on the Form must not exceed 250 characters.',
						]"></v-text-field>
					<v-tooltip top v-if="$vuetify.breakpoint.smAndUp">
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon v-bind="attrs" v-on="on" class="help-button" :ripple="false">
								<v-icon size="24">icon-circle-help-stroke</v-icon>
							</v-btn>
						</template>
						<span>
							Add any extra details or comments about this form, like where it’s used or its purpose. This
							is optional and won’t be shown to the people filling out the form.
						</span>
					</v-tooltip>
				</div>
				<div class="d-flex flex-nowrap">
					<v-text-field
						outlined
						required
						label="Headline of the Form *"
						class="field-border-radius"
						v-model.trim="form.headline"
						:rules="[
							(value) => (!!value && value?.trim().length > 0) || 'Headline of the Form is required.',
							(value) =>
								!value || value.length <= 150 || 'Headline of the Form must not exceed 150 characters.',
						]"></v-text-field
					><v-tooltip top v-if="$vuetify.breakpoint.smAndUp">
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon v-bind="attrs" v-on="on" class="help-button" :ripple="false">
								<v-icon size="24">icon-circle-help-stroke</v-icon>
							</v-btn>
						</template>
						<span>
							Write a short headline that will appear at the top of the form. Use it to explain what the
							form is for or give instructions to the people filling it out.
						</span>
					</v-tooltip>
				</div>
			</template>

			<div class="grey px-2 lighten-3 grey--text text--darken-3 rounded-xl py-4">
				<h6>Form Fields</h6>
				<div v-for="field in form.FormFields" :key="field.name" class="mt-2">
					<div style="position: relative">
						<v-text-field
							v-if="isEmailField(field)"
							readonly
							disabled
							outlined
							hide-details
							type="email"
							:required="isRequired(field)"
							:label="getLabel(field)"
							:placeholder="field.placeholder"></v-text-field>

						<vue-tel-input
							v-else-if="isPhoneNumberField(field)"
							readonly
							disabled
							valid-color="#000000"
							class="phoneNumberField"
							:label="getLabel(field)"
							:border-radius="0"
							:validCharactersOnly="true"
							:required="isRequired(field)"
							:placeholder="getLabel(field)"
							:inputOptions="{ placeholder: 'Phone Number *' }" />

						<v-text-field
							v-else-if="field.type == FieldType.Text"
							readonly
							disabled
							outlined
							hide-details
							:required="isRequired(field)"
							:label="getLabel(field)"
							:placeholder="field.placeholder"
							class="field-border-radius"></v-text-field>

						<v-text-field
							v-else-if="field.type == FieldType.Date"
							readonly
							disabled
							outlined
							hide-details
							:required="isRequired(field)"
							:label="getLabel(field)"
							:placeholder="field.placeholder"
							class="field-border-radius"></v-text-field>

						<v-text-field
							v-else-if="field.type == FieldType.Number"
							readonly
							disabled
							outlined
							hide-details
							:required="isRequired(field)"
							:label="getLabel(field)"
							:placeholder="field.placeholder"
							class="field-border-radius"></v-text-field>

						<v-textarea
							v-else-if="field.type == FieldType.TextArea"
							readonly
							disabled
							outlined
							hide-details
							:rows="2"
							:required="isRequired(field)"
							:label="getLabel(field)"
							:placeholder="field.placeholder" />
						<div
							v-if="field.canBeDeleted"
							class="absolute top-0 bottom-0 z-index-1"
							style="right: -14px; position: absolute; top: 0; bottom: 0">
							<div class="d-flex flex-column justify-center align-center" style="height: 100%">
								<v-btn fab x-small @click="removeField(field)">
									<v-icon color="primary">icon-minus-circle-solid</v-icon>
								</v-btn>
							</div>
						</div>
					</div>
				</div>
			</div>
			<v-btn
				large
				class="mt-3"
				elevation="0"
				rounded
				color="primary"
				style="width: 100%"
				@click="openFieldEditor">
				<v-icon>icon-plus</v-icon>
				Add a New Field</v-btn
			>
			<div>
				<v-autocomplete
					class="mt-5"
					outlined
					label="Recording notifications go to *"
					auto-select-first
					no-data-text="Start typing to find a user"
					v-model="form.selfServeUserId"
					item-text="searchLabel"
					item-value="id"
					:items="teamMemberItems"
					:maxLength="100"
					:rules="[(v) => !!v || 'Recording notifications go to is required.']">
					<template v-slot:selection="{ attr, on, item, selected }">
						<span v-if="item.name">{{ item.name }}</span>
						<span v-else-if="item.phone">{{ item.phone }}</span>
						<span v-else-if="item.email">{{ item.email }}</span>
					</template>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title>{{ item.name }}</v-list-item-title>
							<v-list-item-subtitle
								><span v-if="item.phone">{{ item.phone }}</span></v-list-item-subtitle
							>
							<v-list-item-subtitle
								><span v-if="item.email">{{ item.email }}</span></v-list-item-subtitle
							>
						</v-list-item-content>
					</template>
				</v-autocomplete>
			</div>
			<FormFieldEditor ref="fieldEditor" :dialog-open="fieldEditorOpen" @addField="addField"></FormFieldEditor>
		</v-container>
	</v-form>
</template>
<script lang="ts" setup>
	import FormField from "@/types/forms/FormField";
	import Form from "../../types/forms/Form";
	import { onMounted, ref } from "vue";
	import { FieldType } from "../../types/forms/FieldType";
	import { FieldValidationType } from "../../types/forms/FieldValidationType";
	import FormFieldEditor from "./FormFieldEditor.vue";
	import api from "@/api";

	const fieldEditor = ref<typeof FormFieldEditor>();
	const fieldEditorOpen = ref<boolean>(false);
	const form = ref<Form>({} as Form);
	const teamMemberItems = ref([]);

	const props = defineProps<{
		form: Form;
		editing: boolean;
	}>();

	defineExpose({
		getDefaultForm,
		reset,
		edit,
	});

	const emit = defineEmits<{}>();

	function openFieldEditor() {
		fieldEditor?.value.open();
	}

	function getDefaultForm(): Form {
		return form.value;
	}

	onMounted(async () => {
		if (props.form?.id) {
			form.value = props.form;
		} else {
			form.value = await api.getDefaultForm();
		}
		api.getUsers({ page: 1, pageSize: 1000 }).then((result: { rows: never[] }) => {
			result.rows.forEach((item: { email: string; searchLabel: string; name: string; phone: string }) => {
				item.searchLabel = `${item.name} ${item.email} ${item.phone}`;
			});

			teamMemberItems.value = result.rows;
		});
	});

	function isPhoneNumberField(field: FormField): boolean {
		if (field?.type == FieldType.Text) {
			if (field.FieldValidators?.find((x) => x.type == FieldValidationType.Phone)) {
				return true;
			}
		}

		return false;
	}

	function isEmailField(field: FormField): boolean {
		if (field?.type == FieldType.Text) {
			if (field.FieldValidators?.find((x) => x.type == FieldValidationType.Email)) {
				return true;
			}
		}

		return false;
	}

	function removeField(field: FormField): boolean {
		const index = form.value.FormFields.indexOf(field);

		if (index != -1) {
			form.value.FormFields.splice(index, 1);
			return true;
		}

		return false;
	}

	function addField(newField: FormField): void {
		form.value.FormFields.push(newField);
	}

	function isRequired(field: FormField): boolean {
		const validator = field.FieldValidators?.find((x) => x.type == FieldValidationType.Required);
		return validator != null;
	}

	function getLabel(field: FormField) {
		const required = isRequired(field);
		const label = `${field.name}${required ? " *" : ""}`;
		return label;
	}

	function edit(model: Form) {
		form.value = model;
	}

	async function reset() {
		form.value = await api.getDefaultForm();
	}
</script>
<style scoped>
	.phoneNumberField {
		height: 54px;
		margin-bottom: 12px;
		border-color: rgba(0, 0, 0, 0.3);
	}

	.phoneNumberField ::placeholder {
		color: rgba(0, 0, 0, 0.38);
	}
</style>
