<template>
	<v-card :class="cardClass()">
		<v-card-text>
			<div class="header">
				<div>
					<h2><span class="action-header-record">Start</span> a Recording Now.</h2>
				</div>
			</div>
			<!--Recording name-->
			<v-text-field
				tabindex="0"
				ref="recordingNameInput"
				v-model="recordingName"
				:placeholder="placeholderRecordingName"
				label="Session Name"
				outlined
				dense
				clearable
				class="field-border-radius"
				:hide-details="!recordingName || recordingName.length < 150"
				maxLength="255"
				counter="255"
				@change="updateRecordingName"
				:class="allowReset ? 'reset' : ''" />
			<ProjectAutoComplete :selected-project="newSelectedProject" @change="updateProject" />

			<div class="recording-disclaimer">
				Click “Start Recording” and a new tab will open with your camera and microphone ready to go.
			</div>
		</v-card-text>
		<v-card-actions>
			<v-spacer />

			<!--Close-->
			<v-btn text elevation="0" @click="close"> Back </v-btn>

			<!--Start-->
			<v-btn
				class="btn-start-recording"
				:disabled="!recordingName || recordingName.trim() === ''"
				elevation="0"
				@click="startInstantMeeting"
				:loading="isLoading">
				Start Recording
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
	import api from "../api";
	import "../helpers/emoji";
	import tokenHandler from "../helpers/tokenHandler";
	import constants from "../api/constants";
	import moment from "moment-timezone";
	import { AsyncPreScreenBody, AsyncPreScreenHead } from "../helpers/string";
	import DeviceUtility from "@/helpers/device";
	import * as Sentry from "@sentry/vue";
	import ProjectAutoComplete from "./ProjectAutoComplete.vue";
	import { hasFreeTrialEnded } from "../helpers/hasFreeTrialEnded.js";

	export default {
		components: {
			ProjectAutoComplete,
		},
		props: {
			customerId: String,
			actionText: String,
			actioningText: String,
			allowJoin: Boolean,
			buttonIcon: String,
			showHeader: Boolean,
			projectId: String,
		},
		data() {
			return {
				vueTelProps: window.VueTelProps,
				starting: false,
				validPhone: false,
				autoSetName: false,
				autoSetNameValue: "",
				isLoading: false,
				phone: "",
				name: "",
				recordingName: "",
				meetingUrl: "",
				actioningTextOverride: "",
				organization: {},
				conversationType: "async",
				autoStartRecordings: false,
				me: null,
				newSelectedProject: null,
				projectSelectItems: [],
				placeholderRecordingName: "",
			};
		},
		computed: {
			dynamicActioningText() {
				if (this.actioningTextOverride) {
					return this.actioningTextOverride;
				}
				return this.starting ? this.actioningText : this.actionText;
			},
			dynamicName() {
				return this.nameOverride ?? this.name;
			},
			disablePhoneNumberEdit() {
				return !!this.meetingUrl;
			},
			allowReset() {
				return this.meetingUrl;
			},
		},
		async mounted() {
			this.me = await api.getMe();
			this.recordingName = this.getConversationName();
			this.placeholderRecordingName = this.recordingName;
			this.reset();
			if (this.projectId) {
				const project = await api.getProject(this.projectId);
				this.newSelectedProject = project;
			}
			this.organization = this.$root.$organization;
			this.autoStartRecordings = this.organization.autoStartRecordings;
			this.notificationTemplate =
				this.conversationType == "async"
					? this.organization.notificationTemplateAsync
					: this.organization.notificationTemplate;
			this.defaultNotificationTemplate = this.notificationTemplate;

			if (this.customerId) {
				const result = await api.getCustomers({
					where: {
						id: this.customerId,
					},
				});

				if (result.count > 0) {
					this.setCustomer(result.rows[0]);
				}
			}
		},
		methods: {
			async updateProject(project) {
				this.newSelectedProject = project;
			},
			cardClass() {
				return `conversation-card ${DeviceUtility.isMobile ? "conversation-bottom" : ""} ${
					this.conversationType
				}`;
			},
			startButtonDisabled() {
				return !this.validPhone || this.starting || !!this.actioningTextOverride;
			},
			close() {
				this.reset();
				if (DeviceUtility.isMobile) {
					if (!this.$route.query.from) {
						this.$router.push("/");
					} else {
						this.$router.go(-1);
					}
				}
				this.$emit("close-menu", true);
			},
			reset() {
				this.meetingUrl = "";
				this.phone = "";
				this.autoSetName = "";
				this.autoSetNameValue = "";
				this.notificationTemplate =
					this.conversationType == "async"
						? this.organization.notificationTemplateAsync
						: this.organization.notificationTemplate;
				setTimeout(() => {
					try {
						this.$refs.recordingNameInput.$refs.input.focus();
					} catch (e) {}
				}, 500);
			},
			updateRecordingName() {
				if (!this.recordingName || this.recordingName.length == 0) {
					this.recordingName = this.getConversationName();
				}
			},
			// TODO: Consolidate this into a helper or single Action dialog component
			getTeamMemberName() {
				let name = null;

				if (this.me?.user.firstname) {
					name = this.me?.user.firstname;
				}

				if (this.me?.user.lastname) {
					name = `${name} ${this.me?.user.lastname}`;
				}

				if (!name) {
					name = this.me?.user.email;
				}

				return name ?? "-";
			},
			getConversationName() {
				const teamMember = this.getTeamMemberName();
				const now = moment().format("M/D/YYYY");
				const conversationName = `Recording from ${teamMember} on ${now}`;
				return conversationName;
			},
			countCharacters(value) {
				return value?.unicodeLength();
			},
			pasteEvent(event) {
				this.phone = event.clipboardData?.getData("text")?.replace(/\D/g, "");
				// If a user pastes into the vue-tel-input, force it to reformat itself.
				this.$nextTick(function () {
					try {
						this.$refs.phoneInput.onInput();
					} catch (e) {}
				});
			},
			// Fires when the correctness of the vue-tel-input's phone number changes (from true to false or vice-versa) and when the component is mounted.
			validate(args) {
				this.validPhone = args.valid;
			},
			// Fires when the vue-tel-input's input changes.
			async matchPhone(number, phoneObject) {
				if (!this.validPhone) {
					// bail on invalid numbers
					return;
				}

				// Make sure that even if the user changes the formatting of their phone number, we always internally
				// update it to formatted version. This way, when the phone number gets saved to the database, it is
				// always properly formatted.
				this.phone = phoneObject.formatted;

				if (!number && this.autoSetName) {
					this.name = "";
				}

				const result = await api.getCustomers({
					where: {
						"$PhoneNumbers.phoneNumber$": {
							$like: `%${number}%`,
						},
					},
				});

				if (result.count > 0) {
					this.setCustomer(result.rows[0]);
				}
			},
			setCustomer(customer) {
				this.name = customer.firstname + " " + customer.lastname;
				this.autoSetName = true;
				this.autoSetNameValue = this.name;

				if (customer.EmailAddresses?.length > 0) {
					this.email = customer.EmailAddresses[0].emailAddress;
				}
			},
			join() {
				window.open(this.meetingUrl, "_blank");
			},
			async startInstantMeeting() {
				// Safari Fix - Pre-open window before async.
				if (await hasFreeTrialEnded(this.organization)) {
					this.$root.$freeTrialEndedDialog.open(this.organization);
				} else {
					let win = null;
					let popupOpened = false;
					let recordingUrl = null;
					const androidPwa = DeviceUtility.isAndroid && DeviceUtility.isPwaInstalled;

					try {
						if (!androidPwa) {
							win = window.open("", "_blank");
						}

						this.isLoading = true;

						if (win && win.document && win.document.body) {
							win.document.head.innerHTML = AsyncPreScreenHead;
							win.document.body.innerHTML = AsyncPreScreenBody(window.location.origin);
							popupOpened = true;
						}

						const me = await api.getMe();
						if (this.newSelectedProject && this.selectedCustomer?.id) {
							const selectedContact = this.selectedCustomer;
							let contactExistance = false;
							if (!this.newSelectedProject.Customers) {
								this.newSelectedProject.Customers = [];
							}
							for (const contact of this.newSelectedProject.Customers) {
								if (contact.id === selectedContact.id) {
									contactExistance = true;
									break;
								}
							}
							if (!contactExistance) {
								await api.updateProject(this.newSelectedProject.id, {
									Customers: [...this.newSelectedProject.Customers, selectedContact],
								});
							}
						}
						const resp = await api.createConversation({
							forcedUserId: me.user.id,
							customerId: this.customerId,
							name: this.recordingName ?? null,
							type: constants.CONVERSATION_TYPE.ASYNC,
							initiationType: constants.INITIATION_TYPE.ADMIN,
							browserId: tokenHandler.getBrowserId(),
							browserToken: await tokenHandler.getOrRefreshToken(),
							autoStartRecordings: true,
							redirectToHostEditor: true,
							projectId: this.newSelectedProject?.id,
						});

						recordingUrl = resp.url;

						if (popupOpened) {
							win.location = recordingUrl;
							if (DeviceUtility.isMobile) {
								if (this.$route.query.from !== "homepage") {
									this.$router.go(-1);
								} else {
									this.$router.push({
										name: "conversation",
										params: {
											id: resp.conversation.id,
										},
										query: {
											from: "homepage",
										},
									});
								}
							} else {
								const currentUrl = window.location.href;
								if (currentUrl.includes("/projects")) {
									this.$emit("close-menu");
								} else {
									this.$router.push({
										name: "conversation",
										params: {
											id: resp.conversation.id,
										},
									});
								}
							}
						} else {
							if (androidPwa) {
								console.log("Android PWA. Redirecting current tab");
								Sentry.captureMessage("Android PWA Redirect", {
									level: "info",
								});
							} else {
								console.warn("Unable to open popup. Redirecting current tab");
							}
							window.location = recordingUrl;
						}
					} catch (e) {
						Sentry.captureException(e, {
							tags: {
								method: "startInstantMeeting",
								file: "ActionStartRecording",
							},
						});

						if (win) {
							win.close();
						}

						console.error("Unable to to start recording", e);

						// If all else fails
						if (recordingUrl) {
							window.location = recordingUrl;
							return;
						}

						throw new Error("An error occurred while starting your recording");
					} finally {
						this.isLoading = false;
					}
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.recording-disclaimer {
		color: #5e5c6e;
		font-size: 0.9em;
		text-align: center;
		padding: 24px;
	}
</style>
