<template>
	<div>
		<v-autocomplete
			v-if="density"
			:autocomplete="autocomplete"
			density="compact"
			class="autocomplete"
			hide-details
			outlined
			ref="projectSearch"
			:label="density ? 'Project' : 'Project (Optional)'"
			auto-select-first
			:search-input.sync="search"
			:no-data-text="search ? 'No projects found.' : 'Start typing to find a project.'"
			:placeholder="placeholder"
			counter="255"
			:maxLength="255"
			:disabled="hasFreeTrialEnded"
			v-model="localSelectedProject"
			item-text="name"
			item-value="id"
			return-object
			:items="filteredProjectItems"
			@blur="projectSearchBlur">
			<template v-slot:selection="{ item }">
				<!-- item name with break word on overflow-->
				<div class="text-truncate">{{ item.name }}</div>
			</template>
			<template v-slot:item="{ item }">
				<v-list-item-content v-if="!item.edit">
					<v-list-item-title v-if="item.name">{{ item.name }}</v-list-item-title>
				</v-list-item-content>
			</template>
			<template v-if="projectsLoaded && filteredProjectItems.filter(Boolean).length == 0 && search" v-slot:append>
				<v-slide-x-reverse-transition mode="out-in">
					<v-tooltip :open-on-hover="!isTouch" :open-on-click="false" :open-on-focus="false" top>
						<template v-slot:activator="{ on, attrs }">
							<v-icon class="create-new-project" v-bind="attrs" v-on="on" @click="createNewProject">
								icon-plus
							</v-icon>
						</template>
						<span>{{ "Create a new project." }}</span>
					</v-tooltip>
				</v-slide-x-reverse-transition>
			</template>
		</v-autocomplete>
		<v-autocomplete
			v-else
			:autocomplete="autocomplete"
			dense
			class="autocomplete"
			hide-details
			outlined
			ref="projectSearch"
			label="Project (Optional)"
			auto-select-first
			:search-input.sync="search"
			:no-data-text="search ? 'No projects found.' : 'Start typing to find a project.'"
			:placeholder="placeholder"
			counter="255"
			:maxLength="255"
			:disabled="hasFreeTrialEnded"
			v-model="localSelectedProject"
			item-text="name"
			item-value="id"
			return-object
			:items="filteredProjectItems"
			@blur="projectSearchBlur">
			<template v-slot:selection="{ item }">
				<div class="text-truncate">{{ item.name }}</div>
			</template>
			<template v-slot:item="{ item }">
				<v-list-item-content v-if="!item.edit">
					<v-list-item-title v-if="item.name">{{ item.name }}</v-list-item-title>
				</v-list-item-content>
			</template>
			<template v-if="projectsLoaded && filteredProjectItems.filter(Boolean).length == 0 && search" v-slot:append>
				<v-slide-x-reverse-transition mode="out-in">
					<v-tooltip :open-on-hover="!isTouch" :open-on-click="false" :open-on-focus="false" top>
						<template v-slot:activator="{ on, attrs }">
							<v-icon class="create-new-project" v-bind="attrs" v-on="on" @click="createNewProject">
								icon-plus
							</v-icon>
						</template>
						<span>{{ "Create a new project." }}</span>
					</v-tooltip>
				</v-slide-x-reverse-transition>
			</template>
		</v-autocomplete>
		<CreateProjectDialog ref="createProjectDialog" :default-name="search" />
	</div>
</template>

<script>
	import { hasFreeTrialEnded } from "@/helpers/hasFreeTrialEnded";
	import api from "../api";
	import CreateProjectDialog from "./CreateProjectDialog.vue";
	import { AccessType } from "@/enums/AccessType";

	export default {
		components: {
			CreateProjectDialog,
		},
		props: {
			selectedProject: {
				type: Object,
				required: false,
			},
			density: {
				type: Boolean,
				default: false,
			},
			placeholder: {
				type: String,
				default: "Start typing to find a project",
			},
		},
		data() {
			return {
				filteredProjectItems: [],
				autocomplete: Math.random(),
				me: null,
				search: "",
				projectsLoaded: false,
				hasFreeTrialEnded: false,
				isTouch: false,
			};
		},
		computed: {
			localSelectedProject: {
				get() {
					return this.selectedProject;
				},
				set(newValue) {
					this.$emit("change", newValue);
					this.$emit("update:selectedProject", newValue);
				},
			},
		},
		async mounted() {
			this.isTouch = matchMedia("(hover: none), (pointer: coarse)").matches;
			this.me = await api.getMe();
			//await this.loadProjects();
			//this.filteredProjectItems = this.projectItems;
			this.projectsLoaded = true;
			if (await hasFreeTrialEnded(this.me.organization)) {
				this.hasFreeTrialEnded = true;
			}
		},
		watch: {
			async search(val) {
				await this.performSearch(val);
			},
		},
		methods: {
			async performSearch(val) {
				if (!this.me) {
					// we still want an initial set on load, but we need the me object to be loaded
					setTimeout(() => {
						this.performSearch(val);
					}, 1000);
					return;
				}
				// get projects matching search
				const projectItems = await api.getProjects({
					where: {
						name: { $ilike: `%${val ?? ""}%` },
					},
					pageSize: 10,
				});
				// get shares
				const projectShares = await api.getProjectShares({
					pageSize: 1000, // do we need this? we limit by project id, not sure if we can have more shares than projects
					where: {
						accessType: { $or: [AccessType.Editor, AccessType.Contributor] },
						userIdentifier: { $or: [this.me.account.id, this.me.user.email] },
						projectId: { $in: projectItems.rows.map((project) => project.id) },
					},
				});
				this.filteredProjectItems = projectItems.rows.filter((project) => {
					return projectShares.rows.some((share) => share.projectId === project.id);
				});
				this.filteredProjectItems.push(this.localSelectedProject);
			},
			createNewProject() {
				const newProjectName = this.search;
				this.projectSearchBlur();
				this.$refs.createProjectDialog.open(newProjectName).then((record) => {
					if (record) {
						this.filteredProjectItems.push(record);
						this.localSelectedProject = record;
					}
				});
			},
			projectSearchBlur() {
				this.$refs?.projectSearch?.blur();
				this.search = "";
				if (this.$refs.projectSearch.internalSearch) {
					this.$refs.projectSearch.internalSearch = "";
				}
			},
			isAdmin() {
				if (this.me?.account.roles[0].name.toLowerCase() === "user") {
					return false;
				}
				return true;
			},
		},
	};
</script>

<style scoped>
	.project-search {
		max-height: fit-content;
	}
	.create-new-project {
		color: #2d74ff;
	}
</style>
